import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "./apex.png";

export default function Navbar() {
    return (
        <div className="navbar">
            {/* Navbar Logo Section */}
            <Link to="/" className="nav-logo link-reset">
                <img className="logo" src={logo} alt="Apex Chess Academy Logo" />
                <div className="logo-text">
                    <span className="title">Apex Chess Academy</span>
                    <span className="slogan">~Believe in Moves!</span>
                </div>
            </Link>

            {/* Navbar Menu Section */}
            <ul className="nav-menu">
                <li className="nav-menu-item">
                    <Link to="/" className="nav-link">Home</Link>
                </li>

                <li className="nav-menu-item dropdown">
                    <span className="nav-link">About Us</span>
                    <ul className="dropdown-menu">
                        <li><Link to="/about-team" className="dropdown-item">About Apex</Link></li>
                        <li><Link to="/about-head" className="dropdown-item">About Head Coach</Link></li>
                    </ul>
                </li>

                <li className="nav-menu-item dropdown">
                    <span className="nav-link">Success</span>
                    <ul className="dropdown-menu">
                        <li><Link to="/testimonials" className="dropdown-item">Testimonials</Link></li>
                        <li><Link to="/stories" className="dropdown-item">Stories</Link></li>
                    </ul>
                </li>

                {/* Updated Courses/Fees Dropdown with 3 Items */}
                <li className="nav-menu-item dropdown">
                    <span className="nav-link">Courses/Fees</span>
                    <ul className="dropdown-menu">
                        <li><Link to="/courses-vejalpur" className="dropdown-item">Vejalpur Branch</Link></li>
                        <li><Link to="/courses-bopal" className="dropdown-item">Bopal Branch</Link></li>
                        <li><Link to="/game-analysis" className="dropdown-item">Game Analysis</Link></li>
                    </ul>
                </li>

                {/* FAQ Link */}
                <li className="nav-menu-item">
                    <Link to="/faqs" className="nav-link">FAQ's</Link>
                </li>

                {/* Contact Link */}
                <li className="nav-menu-item">
                    <Link to="/contact" className="nav-link">Contact</Link>
                </li>
            </ul>
        </div>
    );
}
