import React from "react";
import "./Testimonials.css";
import Saumil from "./Saumil.jpg";
import Shikha from "./Shikha.jpg";
import Shreetu from "./Shreetu.jpg";
import Raj from "./Raj.jpg";
import Advik from "./Advik.jpg";
import Falgun from "./Falgun.jpg";
import Sachin from "./Sachin.jpg";
import Aarav from "./Aarav.jpg";
import Jihan from "./Jihan.jpg";    
import Footer from "../../Footer/Footer";
import Krishav from "./Krishav.jpg";
import Copyright from "../../Copyright/Copyright";


const testimonials = [
    {
        title: "Most Beautiful Chapter in my Life Book",
        content: `I have been learning from Hemal Sir for the last 3-4 years and have got tremendous results in a very short period of time. In 2-3 years, my rating just jumped from around 1300 to 1800 (Peak Rating) and his coaching style, friendly attitude, and rapport with me have been fabulous.`,
        author: "Saumil Nair",
        image: Saumil,
    },
    {
        title: "Incessant Dedication Makes him an Ideal Teacher",
        content: `In all the years I've been in the world of chess with Hemal sir, he has taught me about the endless possibilities of those 64 squares. Not only did I learn about insights, openings, and tactics, but I also learned how to evolve as a sportsperson.`,
        author: "Dr. Shikha Shah",
        image: Shikha,
    },
    {
        title: "A Real Guide & Mentor",
        content: `Hemal sir gives you enough freedom to experiment, and his thirst to continually update himself always helps his students to learn new things. He is not just a coach but also a guide and a mentor. He's bright, motivational, passionate, disciplined, keen to get better. More importantly, he instills these same qualities in his students as well. People say change is hard. It is, but it is much more achievable when you have him as your coach and mentor to guide you through the process. He is always approachable for any kind of queries. He also gives excellent psychological support. It has been and will always be great working with him.`,
        author: "Shreetu Nandi",
        image: Shreetu,
    },
    {
        title: "Your Enthusiasm towards Chess is Unmatched",
        content: `Your dedication drives me towards achieving my goals. Your enthusiasm towards the game is unmatched, and I hope to grow into a successful chess player under your guidance.`,
        author: "Raj Mathur",
        image: Raj,
    },
    {
        title: "Wonderful Human Being",
        content: `Apart from being a very good chess player and coach, he is a wonderful human being. The main quality of him is that he works very hard for his students and understands their psychology.`,
        author: "Falgun Purohit (Surat, Gujarat)",
        image: Falgun,
    },
    {
        title: "Coach & a Friend",
        content: `Hemal is a perfect combination of coach and friend. He has in-depth knowledge of every aspect of the game and is patient enough to take time to explain every detail.`,
        author: "Sachin",
        image: Sachin,
    },

    {
        title: "Transforming Chess with the Apex Team",
        content: `Learning chess with Hemal sir has truly transformed my chess game. His teaching skills and in- depth knowledge of the game, helped me overcome many of the weaknedepth knowledge of strategiessses in my game. He made learning chess fun and easy to understand. He has a special way of connecting with kids like me, making the lessons both educational and enjoyable. I have improved my focus and problem solving abilities have developed significantly.

Even your team member Kartavya Sir's personalized attention and tailored lessons made a huge difference in my performance, especially in tournament play.              `,
        author: "Advik Sharma",
        image: Advik,
    },

    {
        title: "From Blunders to Brilliance: My Chess Journey with Hemal Sir",
        content: `Hemal Sir has been training me for almost two years, transforming my game with deep strategic insights and expert guidance.
His patience, passion for chess, and unwavering belief in me have been invaluable. Thanks to his coaching, I now approach every game with greater confidence and a stronger understanding of chess fundamentals.`,
        author: "Krishav Shah",
        image: Krishav,
    },

    {
        title: "My Chess Journey with Hemal Sir",
        content: `Thank you, Hemal sir!! With your expert guidance, I'm now thoroughly enjoying my chess journey and excited for the challenges ahead!! Keep inspiring and guiding`,
        author: "Aarav Arpit Shah",
        image: Aarav,
    },

    {
        title: "An Absolute Journey Maker",
        content: `I have been learning from joy sir for past 3 years and from hemal sir for past 2 years. Due to Their dedication and hardwork my rating has gone from 1600 to 2200 within this period of time. I am very greatful to the coaches for being a part of my successful journey.`,
        author: "Jihan Shah",
        image: Jihan,
    },
];

export default function Testimonials() {
    return (
        <div className="testimonials-page">
            <h1 className="page-title">Testimonials</h1>
            <div className="testimonials-container">
                {testimonials.map((testimonial, index) => (
                    <div
                        className={`testimonial ${
                            index % 2 === 0 ? "left" : "right"
                        }`}
                        key={index}
                    >
                        <div className="testimonial-image">
                            <img
                                src={testimonial.image}
                                alt={`Testimonial by ${testimonial.author}`}
                            />
                        </div>
                        <div className="testimonial-content">
                            <h2 className="testimonial-title">{testimonial.title}</h2>
                            <p className="testimonial-text">{testimonial.content}</p>
                            <p className="testimonial-author">-- {testimonial.author}</p>
                        </div>
                    </div>
                ))}
            </div>
            
            {/* Adding Space Before Footer */}
            <div style={{ marginBottom: "30px" }}></div>

            {/* Footer Section */}
            <Footer
                style={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: "20px",
                    gap: "20px",
                }}
            />
            <Copyright/>
        </div>
    );
}
