import React, { useState } from "react";
import "./Contact.css";
import emailjs from "emailjs-com";
import Footer from "../Footer/Footer";

export default function ContactUs() {
    const [formData, setFormData] = useState({
        name: "",
        email_id: "",
        phone_number: "",
        message: "",
    });
    const [statusMessage, setStatusMessage] = useState("");

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.name || !formData.email_id || !formData.phone_number || !formData.message) {
            setStatusMessage("Please fill out all fields.");
            return;
        }

        // Send email to admin with EmailJS
        emailjs
            .sendForm(
                "service_mrz18tu", // Service ID
                "template_9szwlyc", // Admin template ID
                e.target, // The form data
                "iW-qyQ8gnmv8MJnVm" // Public Key
            )
            .then(
                (result) => {
                    console.log("Admin Email Sent: ", result.text);

                    // Send auto-reply email to the user
                    sendAutoReply();

                    // Success message and clear the form
                    setStatusMessage("We will get back to you soon!");
                    setFormData({
                        name: "",
                        email_id: "",
                        phone_number: "",
                        message: "",
                    });
                },
                (error) => {
                    setStatusMessage("There was an error. Please try again.");
                    console.error("Error sending admin email: ", error.text);
                }
            );
    };

    // Send an auto-reply email to the user
    const sendAutoReply = () => {
        emailjs
            .send(
                "service_mrz18tu", // Service ID
                "template_4u7f5hc", // Auto-reply template ID
                {
                    name: formData.name,
                    email_id: formData.email_id,
                },
                "iW-qyQ8gnmv8MJnVm" // Public Key
            )
            .then(
                (result) => {
                    console.log("Auto-reply Email Sent: ", result.text);
                },
                (error) => {
                    console.error("Error sending auto-reply email: ", error.text);
                }
            );
    };

    return (
        <div className="contact-us-page">
            <h1 className="page-title">Contact Us</h1>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name" // Matches {{name}} in EmailJS template
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email_id">Email ID</label>
                    <input
                        type="email"
                        id="email_id"
                        name="email_id" // Matches {{email_id}} in EmailJS template
                        value={formData.email_id}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone_number">Contact Number</label>
                    <input
                        type="tel"
                        id="phone_number"
                        name="phone_number" // Matches {{phone_number}} in EmailJS template
                        value={formData.phone_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message" // Matches {{message}} in EmailJS template
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                    ></textarea>
                </div>
                <button type="submit" className="submit-button">Submit</button>
            </form>

            {statusMessage && <p className="status-message">{statusMessage}</p>}

            {/* Footer Section */}
            <div className="footer-container">
                <Footer />
            </div>
        </div>
    );
}
