import React from "react";
import { motion } from "framer-motion";
import "./about-team.css";
import Madhuri from "./Madhuri.jpg";
import Hemal from "./Hemal.jpg";
import Joy from "./Joy.jpg";
import Kartavya from "./Kartavya.jpg";
import Sachin from "./Sachin.png";
import Falgun from "./Falgun.jpg";
import Apoorva from "./Apoorva.jpg";
import Nivid from "./Nivid.jpg";
import Ankit from "./Ankit.jpg";

const teamMembers = [
  {
    name: "Madhuri Thanki",
    role: "Founder",
    img: Madhuri,
  },
  {
    name: "Hemal Thanki",
    role: "Co-Founder & Head Coach",
    img: Hemal,
  },
  {
    name: "Joy Chauhan",
    role: "FIDE Instructor",
    img: Joy,
  },
  {
    name: "Kartavya Anadkat",
    role: "Strong & Experienced Chess Player",
    img: Kartavya,
  },
  {
    name: "Sachin Prasad",
    role: "Development Instructor",
    img: Sachin,
  },
  {
    name: "Falgun Purohit",
    role: "Chess Coach",
    img: Falgun,
  },
  {
    name: "Apoorva Rana",
    role: "National Instructor",
    img: Apoorva,
  },
  {
    name: "Nivid Rana",
    role: "National Instructor",
    img: Nivid,
  },
  {
    name: "Ankit Chudasama",
    role: "Chess Instructor",
    img: Ankit,
  },
];

const Team = () => {
  return (
    <div className="team-container">
      <motion.h1
        className="team-title"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        Meet Our Expert Team
      </motion.h1>

      <motion.p
        className="team-description"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        Together, we are shaping the future of chess and empowering aspiring players!
      </motion.p>

      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <motion.div
            key={index}
            className="team-card"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <img src={member.img} alt={member.name} className="team-img" />
            <h3 className="team-name">{member.name}</h3>
            <p className="team-role">{member.role}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Team;
