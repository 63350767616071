// import React, { useState } from "react";
// import emailjs from "@emailjs/browser";

// const GameSubmission = () => {
//   const [file, setFile] = useState(null);
//   const [userName, setUserName] = useState("");
//   const [userEmail, setUserEmail] = useState("");
//   const [statusMessage, setStatusMessage] = useState("");

//   const handleFileChange = (e) => {
//     const selectedFile = e.target.files[0];
//     if (selectedFile && selectedFile.name.endsWith(".pgn")) {
//       setFile(selectedFile);
//     } else {
//       alert("Only .pgn files are allowed.");
//       setFile(null);
//     }
//   };

//   const handlePayment = async () => {
//     if (!file || !userName || !userEmail) {
//       alert("Please fill all fields and upload a valid .pgn file.");
//       return;
//     }

//     const res = await loadRazorpay();

//     if (!res) {
//       alert("Razorpay SDK failed to load. Please check your connection.");
//       return;
//     }

//     const options = {
//       key: "YOUR_RAZORPAY_KEY", // Replace with your Razorpay key
//       amount: 5000, // 50 INR in paise
//       currency: "INR",
//       name: "Apex Chess Academy",
//       description: "Game Analysis Submission",
//       handler: function (response) {
//         alert("Payment successful! Payment ID: " + response.razorpay_payment_id);
//         setStatusMessage("Payment Successful! Submitting your game...");
//         handleSubmit(response.razorpay_payment_id);
//       },
//       prefill: {
//         name: userName,
//         email: userEmail,
//       },
//       theme: {
//         color: "#56c8a4",
//       },
//     };

//     const rzp = new window.Razorpay(options);
//     rzp.open();
//   };

//   const handleSubmit = (paymentId) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => {
//       const base64File = reader.result;

//       const templateParams = {
//         user_name: userName,
//         email: userEmail,
//         message: `Payment ID: ${paymentId}`,
//         pgn_file: base64File,
//       };

//       emailjs
//         .send(
//           "YOUR_SERVICE_ID",
//           "YOUR_TEMPLATE_ID",
//           templateParams,
//           "YOUR_PUBLIC_KEY"
//         )
//         .then(
//           (response) => {
//             console.log("SUCCESS!", response.status, response.text);
//             setStatusMessage("Game submitted successfully! We will contact you soon.");
//             setFile(null);
//             setUserName("");
//             setUserEmail("");
//           },
//           (err) => {
//             console.error("FAILED...", err);
//             setStatusMessage("Failed to submit game. Please try again.");
//           }
//         );
//     };
//     reader.onerror = (error) => {
//       console.error("File reading error: ", error);
//       alert("Failed to read file.");
//     };
//   };

//   return (
//     <div className="contact-us-page">
//       <h2 className="page-title">Submit Your Game for Analysis</h2>
//       <form className="contact-form" onSubmit={(e) => e.preventDefault()}>
//         <div className="form-group">
//           <label>Your Name</label>
//           <input
//             type="text"
//             placeholder="Enter your name"
//             value={userName}
//             onChange={(e) => setUserName(e.target.value)}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label>Your Email</label>
//           <input
//             type="email"
//             placeholder="Enter your email"
//             value={userEmail}
//             onChange={(e) => setUserEmail(e.target.value)}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label>Upload .pgn File</label>
//           <input type="file" accept=".pgn" onChange={handleFileChange} required />
//         </div>

//         <button type="button" className="submit-button" onClick={handlePayment}>
//           Pay ₹50 & Submit Game
//         </button>

//         {statusMessage && <p className="status-message">{statusMessage}</p>}
//       </form>
//     </div>
//   );
// };

// // Razorpay loader
// const loadRazorpay = () => {
//   return new Promise((resolve) => {
//     const script = document.createElement("script");
//     script.src = "https://checkout.razorpay.com/v1/checkout.js";
//     script.onload = () => resolve(true);
//     script.onerror = () => resolve(false);
//     document.body.appendChild(script);
//   });
// };

// export default GameSubmission;


import React from "react";
import "./GameAnalysis.css";
import qrcode from "./qrcode.jpeg"; // Ensure the correct path

const GameAnalysis = () => {
  return (
    <div className="game-analysis-page">
      <h2 className="page-title">Game Analysis </h2>
      <p>
        Analyzing your chess games is essential for improving your strategy and decision-making skills. At <strong>APEX Chess Academy</strong>, we provide expert game analysis to help you identify mistakes, improve calculation, and enhance your overall gameplay.
      </p>

      <h3>Pricing:</h3>
      <ul>
        <li><strong>₹500 per game</strong> (detailed analysis with feedback)</li>
        <li><strong>₹3000 per full tournament</strong> (all your games from a tournament)</li>
      </ul>

      <h3>How to Submit:</h3>
      <p>
        Interested players can submit their games via email or WhatsApp:
      </p>
      <ul>
        <li>Email: <a href="mailto:apexchessacademy@gmail.com">apexchessacademy@gmail.com</a></li>
        <li>WhatsApp: <a href="https://wa.me/8866888640">8866888640</a></li>
      </ul>

      <h3>Payment Details:</h3>
      <p>
        Payments can be made via the QR code below. Once payment is completed, send us the payment confirmation along with your game files.
      </p>
      <img src={qrcode} alt="QR Code for Payment" className="qr-code" />
    </div>
  );
};

export default GameAnalysis;
