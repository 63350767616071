import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Header from "../components/Header/Header";
import Counter from "../components/Counter/Counter";
import CoursesVejalpur from "../components/Courses/CoursesVejalpur";
import CoursesBopal from "../components/Courses/CoursesBopal";
import GameAnalysis from "../components/Courses/GameAnalysis";
import Experts from "../components/Experts/Experts";
import Footer from "../components/Footer/Footer";
import Copyright from "../components/Copyright/Copyright";
import Stories from "../components/Success/Stories/Stories";
import AboutHead from "../components/About/about-head/about-head";
import Testimonials from "../components/Success/Testimonials/Testimonials";
import Contact from "../components/Contact/Contact";
import Faqs from "../components/Faqs/Faqs";
import AboutTeam from "../components/About/about-team/about-team";
import "./App.css";

export default function App() {
  return (
    <Router>
      <div className="app">
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Counter />
                <Experts />
                <Footer />
                <Copyright />
              </>
            }
          />

          {/* Redirect Route */}
          <Route path="/redirect-to-courses" element={<Navigate to="/courses-vejalpur" replace />} />

          {/* Courses/Fees Routes */}
          <Route path="/courses-vejalpur" element={<CoursesVejalpur />} />
          <Route path="/courses-bopal" element={<CoursesBopal />} />
          <Route path="/game-analysis" element={<GameAnalysis />} />

          {/* Other Routes */}
          <Route path="/stories" element={<Stories />} />
          <Route path="/about-head" element={<AboutHead />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/about-team" element={<AboutTeam />} />
        </Routes>
      </div>
    </Router>
  );
}
