import React from "react";
import "./Product.css";

export default function Product() {
    return (
        <div className="product">
            <div className="top">
                {/* YouTube Video Section */}
                <div className="video-container">
                    <iframe
                        width="100%"
                        height="280"
                        src="https://www.youtube.com/embed/OVndq8UDQpI"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
}
