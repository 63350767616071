import React from 'react';
import { Link } from 'react-router-dom';
import './Courses.css';

const CourseFees = () => {
    const branchTwoFees = [
        { level: 'Level 1 to 3', twoDays: '₹2000', threeDays: '₹3000', fiveDays: '₹4500' },
        { level: 'Level 4 & 5', twoDays: '₹2400', threeDays: '₹3600', fiveDays: '₹5000' },
        { level: 'Level 6 & 7', twoDays: '₹2800', threeDays: '₹4000', fiveDays: '₹6000' },
    ];

    const levelWiseSessions = [
        { level: 'Level 1', sessions: 24, fees: '₹4000' },
        { level: 'Level 2', sessions: 64, fees: '₹12000' },
        { level: 'Level 3', sessions: 64, fees: '₹13000' },
        { level: 'Level 4', sessions: 64, fees: '₹14000' },
        { level: 'Level 5', sessions: 64, fees: '₹15000' },
        { level: 'Level 6', sessions: 64, fees: '₹16000' },
        { level: 'Level 7', sessions: 64, fees: '₹16000' },
    ];

    return (
        <div className="course-fees-page">
            <h1 className="page-title">Fee Structure - Bopal Branch</h1>

            <div className="fees-section">
                <table className="batch-table">
                    <thead>
                        <tr>
                            <th>Level</th>
                            <th>2 Days/Week</th>
                            <th>3 Days/Week</th>
                            <th>5 Days/Week</th>
                        </tr>
                    </thead>
                    <tbody>
                        {branchTwoFees.map((fee, index) => (
                            <tr key={index}>
                                <td>{fee.level}</td>
                                <td>{fee.twoDays}</td>
                                <td>{fee.threeDays}</td>
                                <td>{fee.fiveDays}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p>Fees are payable before the start of the course and are non-refundable.</p>
            </div>

            {/* Level-wise session packages */}
            <div className="fees-section">
                <h2>Level-wise Session Packages</h2>
                <table className="batch-table">
                    <thead>
                        <tr>
                            <th>Level</th>
                            <th>Number of Sessions (1 hr each)</th>
                            <th>Fees (₹)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {levelWiseSessions.map((item, index) => (
                            <tr key={index}>
                                <td>{item.level}</td>
                                <td>{item.sessions}</td>
                                <td>{item.fees}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p>All sessions are of 1 hour each. Full payment is to be made before starting. No refunds applicable.</p>
            </div>

            <div className="contact-section">
                <h2>Contact Us</h2>
                <p>Email: <a href="mailto:apexchessacademy@gmail.com">apexchessacademy@gmail.com</a></p>
                <p>Phone: <a href="tel:+918866888640">+91 8866888640</a></p>
            </div>
        </div>
    );
};

export default CourseFees;
