import React from "react";
import Matrics from "./Metrics";
import "./Counter.css"

export default function Counter(){
    return(
        <div className="counter">
            <Matrics value={"1500+"} title={"Enrolled Students"} />
            <Matrics value={"15+"} title={"Countries  Worldwide"} />
            <Matrics value={"10+"} title={"Top Coaches"} />
            <Matrics value={"2"} title={"Monthly Tournaments"} />
        </div>
    )
}