import React, { useState } from "react";
import "./Faqs.css";

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle answer visibility
  };

  const faqs = [
    {
      question: "What is the right age to start chess?",
      answer: `Research shows that different players excel at different ages. 
        Some started early (e.g., Capablanca at 4, Euwe at 5) and peaked in their 20s, 
        while others started later (e.g., Kasparov at 10, Lasker at 12) and still became legends.`,
    },
    {
      question: "How to choose/plan tournaments?",
      answer: `If your goal is to gain 400 rating points in a year, it's advisable to play tournaments 
        where the average rating is slightly above yours. This means playing around 10 tournaments a year, one per month.`,
    },
    {
      question: "How to choose a coach for my child?",
      answer: `A good coach should encourage independent learning, continuously upgrade their skills, and understand the 
        child's psychology. They should provide learning materials and focus on long-term growth rather than short-term results.`,
    },
    {
      question: "How to handle stress during a tournament?",
      answer: `There are two types of stress: eustress (positive, boosts performance) and distress (negative, affects mental well-being). 
        Players should learn to embrace tournament pressure while avoiding prolonged distress after losses.`,
    },
    {
      question: "What makes APEX Chess Academy unique?",
      answer: `APEX Chess Academy emphasizes six key Indian teaching techniques for chess calculation, 
        including pattern recognition, quick observation, and opening preparation, ensuring well-rounded player development.`,
    },
    {
      question: "Do you provide online chess coaching?",
      answer: `Yes, APEX Chess Academy offers both online and offline coaching sessions. Our online classes are interactive 
        and designed to provide the same learning experience as in-person coaching.`,
    },
    {
      question: "How does APEX Chess Academy track student progress?",
      answer: `We track student progress through periodic assessments, tournament performance analysis, and 
        practice session reviews. Regular feedback is given to help students improve their weaknesses.`,
    },
    {
      question: "What kind of practice material is provided?",
      answer: (
        <>
          We provide PGN files containing analyzed games, puzzles, and exercises for students to practice. 
          These files help players review and improve their gameplay with detailed move-by-move insights. 
          Additionally, students can access resources and weekly study material through our official website:  
          <a 
            href="https://members.apexchess.academy/" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "none", marginLeft: "5px" }}
          >
            APEX Chess Academy
          </a>.
        </>
      ),
    },
    {
      question: "Can beginners join APEX Chess Academy?",
      answer: `Absolutely! We welcome players of all levels, from beginners to advanced players. Our structured 
        curriculum ensures that even beginners can quickly grasp the fundamentals and progress systematically.`,
    },
  ]; // <-- FIXED: Removed extra closing bracket and comma from here

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div
              className={`faq-question ${openIndex === index ? "active" : ""}`}
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <span className="arrow">{openIndex === index ? "▼" : "▶"}</span>
            </div>
            <div className={`faq-answer ${openIndex === index ? "active" : "hidden"}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
