import React from 'react';
import './about-head.css';
import { FaTrophy, FaChessKing, FaMedal, FaGlobe, FaChessBoard } from 'react-icons/fa'; // Relevant icons
import Footer from "../../Footer/Footer";
import hemalsir from "./hemalsir2.jpg";

const AboutHead = () => {
  return (
    <div className="about-head">
      {/* Coach Bio Section */}
      <section className="coach-bio">
        <div className="bio-content">
          <img src={hemalsir} alt="FIDE Trainer Hemal Thanki" className="coach-image" />
          <div className="bio-text">
            <h2>Founder of Apex Chess Academy: Hemal Thanki </h2>
            <p>
              A visionary leader and seasoned chess strategist, FIDE Trainer Hemal Thanki brings over 20 years of rich experience as both a player and coach. 
              With an impressive International FIDE Rating of 2182, his profound knowledge of the game and dedication to nurturing talent have earned him a 
              stellar reputation in the chess community. He has guided countless students, including visually impaired players, to excel at state, national, 
              and international levels.
            </p>
            <p>
              As a coach, Hemal Thanki has been instrumental in producing multiple national champions and star performers in global tournaments. His 
              unique training methodologies, coupled with an unwavering commitment to his students, make him a cornerstone of Apex Chess Academy's success.
            </p>
          </div>
        </div>
      </section>


    {/* Titles and Contributions Section */}
    <section className="titles-contributions">
        <h2><FaChessKing /> FIDE Titles</h2>
        <ul className="achievements-list">
          <li><FaChessKing /> Earned the title of FIDE Arbiter – 2014.</li>
          <li><FaChessKing /> Recognized as a FIDE Instructor – 2018.</li>
          <li><FaChessKing /> Achieved the prestigious title of FIDE Trainer – 2024.</li>
        </ul>
      </section>

      {/* Awards from Government Section */}
      <section className="awards">
        <h2><FaTrophy /> Recognitions and Awards</h2>
        <ul className="achievements-list">
          <li><FaMedal /> Felicitated with the Purusarth Gaurav Award – 2000.</li>
          <li><FaMedal /> Honored with the JaydeepSinh Senior Award – 2014.</li>
          <li><FaMedal /> Recognized with the prestigious Sardar Patel Senior Award – 2016.</li>
        </ul>
      </section>

      {/* Notable Achievements as a Player Section */}
      <section className="achievements">
        <h2><FaTrophy /> Notable Achievements as a Player</h2>
        <ul className="achievements-list">
          <li><FaChessBoard /> Five-Time Senior State Chess Champion, including four consecutive titles (2008, 2011, 2012, 2013, 2014).</li>
          <li><FaTrophy /> Captain of the victorious team at the 17th National Cities Team Chess Championship (2016).</li>
          <li><FaTrophy /> Clinched 1st position at the 6th National Amateur Chess Championship (Below 2300) in 2017.</li>
          <li><FaGlobe /> Represented India and secured 7th position in the World Amateur Chess Championship, Italy (2018).</li>
        </ul>
      </section>


      {/* Coaching Contributions Section */}
      <section className="coaching-contributions">
        <h2><FaChessBoard /> Coaching Contributions</h2>
        <ul className="achievements-list">
          <li><FaChessBoard /> Successfully coached visually impaired players, producing national champions and global performers over 5 years.</li>
          <li><FaChessBoard /> Served as a coach in summer training camps organized by Gujarat and SAG in 2019, 2022, and 2023.</li>
        </ul>
      </section>

      {/* Coaching Philosophy Section */}
      <section className="coaching-philosophy">
        <h2>Coaching Philosophy</h2>
        <p>
          FIDE Trainer Hemal Thanki believes in a holistic and personalized approach to chess training, emphasizing the development of technical expertise, 
          strategic thinking, and mental resilience. His methods inspire discipline, foster creativity, and empower students to excel both on and off the chessboard. 
          By instilling a winning mindset and a passion for lifelong learning, he ensures that his students are equipped to overcome challenges and achieve success 
          in all aspects of life.
        </p>
      </section>
      <section>

      </section>

      {/* Include Footer */}
      <Footer
         style={{
         backgroundColor: "white",
         display: "flex",
         justifyContent: "space-between",
         alignItems: "flex-start",
         padding: "20px",
         gap: "20px",
         }}
        />  

      
    </div>
  );
};

export default AboutHead;
