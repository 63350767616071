import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css"; // Cube animation styles
import "react-awesome-slider/dist/styles.css"; // Default slider styles
import "./Cubeanimation.scss"; // Your custom styles
import "./Stories.css";
import Kritee from "./Kritee.jpg";
import Arham from "./Arham.jpg";
import Anushi from "./Anushi.jpg";
import SohamN from "./SohamN.jpg";
import MarmikShah from "./MarmikShah.jpg";
import Devansh from "./Devansh.jpg";
import Risha from "./Risha.jpg";
import AaravKumar from "./Aaravkumar.jpg";
import Harikrishna from "./Harikrishna.jpg";
import Vishwanath from "./Vishwanath.jpg";
import Kiyana from "./Kiyana.jpg";
import KrishavShah from "./KrishavShah.jpg";
import Kartavya from "./Kartavya.jpg";
import AbhayBandewar from "./AbhayBandewar.jpg";
import Himanshi from "./Himanshi.jpg";
import Soundarya from  "./Soundarya.jpg";
import Ashita from "./Ashita.jpg";
import Jihan from "./Jihan.jpg";
import Ruhani from "./Ruhani.jpg";
import Maharth from "./Maharth.jpg";
import Nitya from "./Nitya.jpg";
import Kian from "./Kian.jpg";
import Shreetu from "./Shreetu.jpg";
import Vihana from "./Vihana.jpg";
import Veer from "./Veer.jpg";
import Dhyaan from "./Dhyaan.jpg";
import Viaan from "./Viaan.jpg";
import Shikha from "./Shikha.JPG";
import Adi from "./Adi.jpg";
import Aditya from "./Aditya.jpg";
import KrishTanna from "./KrishTanna.jpg";
import Aadhya from "./Aadhya.jpg";
import Aadish from "./Aadish.jpg";
import Aaravarpit from "./Aaravarpit.jpg";
import Dirgh from "./Dirgh.jpg";
import Dhanasvi from "./Dhanasvi.jpg";
import Aashvi from "./Aashvi.jpg";
import Dhvit from "./Dhvit.jpg";
import Rishit from "./Rishit.jpg";
import ViniGandhi from "./ViniGandhi.jpg";
import Saumil from "./Saumil.jpg";
import Aatman from "./Aatman.jpg";
import VihanParikh from "./VihanParikh.jpg";



const stories = [
    {
      image: Kartavya,
      name: "Kartavya Anadkat",
      achievements: [
  // International Achievements
  { tournament: "FIDE World Amateur Chess Championship Below 2300 (Oman)", place: "2nd", year: 2023 },

  // National Achievements
  { tournament: "11th National Amateur Chess Championship Below 2300", place: "2nd", year: 2024 },
  { tournament: "U-17 Open Online National School Chess Championship", place: "1st", year: 2021 },
  { tournament: "8th National Amateur (Below 2300) Chess Championship", place: "1st", year: 2019 },

  // State Achievements
  { tournament: "Open Gujarat Senior State Chess Championship", place: "1st", year: 2022 },
  { tournament: "Open Gujarat Senior Blitz State Chess Championship", place: "1st", year: 2022 },
  { tournament: "Open Gujarat Senior Rapid State Chess Championship", place: "1st", year: 2022 },
  { tournament: "Open Gujarat State Team Chess Championship", place: "1st", year: 2022 },
  { tournament: "U-19 Boys Gujarat State Chess Championship", place: "1st", year: 2022 },
  { tournament: "U-18 Boys Gujarat State Chess Championship", place: "2nd", year: 2021 },
  { tournament: "Open Gujarat State Chess Championship", place: "2nd", year: 2020 },
  { tournament: "U-17 Boys Khel Maha Kumbh Gujarat State", place: "1st", year: 2019 },
  { tournament: "U-16 Boys Inter-School Gujarat State Selection", place: "1st", year: 2019 }
],
},
{
  image: Kiyana,
  name: "Kiyana Parihar",
  achievements: [
    // International Achievements
    { tournament: "U-8 Girls Asian Youth Chess Championship", place: "1st", year: 2023 },
    { tournament: "U-8 Girls Asian Youth Rapid Chess Championship", place: "2nd", year: 2023 },
    { tournament: "FIDE World Cup for Youngsters", place: "9th", year: 2024 },

    // National Achievements
    { tournament: "U-7 National School Girls Chess Championship", place: "2nd", year: 2022 },
    { tournament: "U-7 National Girls Chess Championship", place: "4th", year: 2022 },
    { tournament: "12th National School U-9 Girls Chess Championship", place: "7th", year: 2024 },
    { tournament: "U-9 Girls National Chess Championship", place: "1st", year: 2024 },

    // State Achievements
    { tournament: "U-7 Rajasthan State Girls Chess Championship", place: "1st", year: 2022 },
    { tournament: "U-8 Rajasthan State Girls Chess Championship", place: "1st", year: 2022 },
    { tournament: "Woman Senior State Selection", place: "5th", year: 2022 },
    { tournament: "U-13 Girls Rajasthan State Chess Championship", place: "1st", year: 2023 },
    { tournament: "U-11 Girls Rajasthan State Chess Championship", place: "2nd", year: 2023 },
    { tournament: "U-09 Girls Rajasthan State Chess Championship", place: "2nd", year: 2023 },
    { tournament: "U-19 Girls Rajasthan State Chess Championship", place: "3rd", year: 2023 },
    { tournament: "U-14 Rajasthan State School Chess Championship (SGFI)", place: "1st", year: 2024 }
  ],
},

{
  image: Himanshi,
  name: "Himanshi Rathi",
  achievements: [
    // International Achievements
    { tournament: "4th Asian Para Games Hangzhou Chess Competition Standard-Women's Individual-B1 at China", place: "3rd", year: 2023, category: "International" },
    { tournament: "4th Asian Para Games Hangzhou Chess Competition Rapid-Women's Individual-B1 at China", place: "3rd", year: 2023, category: "International" },
  
    // National Achievements
    { tournament: "National Team Chess Championship for Visually Challenged Women - Team Event", place: "1st", year: 2023, category: "National" },
    { tournament: "National B Open Chess Championship for Visually Challenged Women", place: "1st", year: 2022, category: "National" },
    { tournament: "National B Open Chess Championship for Visually Challenged Women", place: "1st", year: 2020, category: "National" },
    { tournament: "National B Open Chess Championship for Visually Challenged Women", place: "1st", year: 2019, category: "National" },
    { tournament: "National B Open Chess Championship for Visually Challenged Women", place: "4th", year: 2023, category: "National" },
  
    // State Achievements
    { tournament: "Visually Challenged Khelmahakumbh Woman Gujarat State", place: "1st", year: 2019, category: "State" }  
  ],
},

{
  image: Soundarya,
  name: "Soundarya Pradhan",
  achievements: [
    // International Achievements
    { tournament: "4th Asian Para Games Hangzhou Chess Competition Rapid-Men's Individual-VI-B1 at China", place: "2nd", year: 2023, category: "International" },
    { tournament: "4th Asian Para Games Hangzhou Chess Competition Rapid-Men's Team-VI-B1 at China", place: "1st", year: 2023, category: "International" },

    // National Achievements
    { tournament: "16th All India Chess Federation for Blind National Chess Championship", place: "3rd", year: 2023, category: "National" },
  ],
},


  {
    image: Kritee,
    name: "Kritee Shah",
    achievements: [
      { tournament: "National Chess Championship", place: "1st", year: 2023 },
      { tournament: "Gujarat State Selection U-13 Chess Championship", place: "1st", year: 2018 },
      { tournament: "Gujarat State Selection U-15 Chess Championship", place: "2nd", year: 2018 },
      { tournament: "National Women State Team Selection", place: "2nd", year: 2020 },
    ],
  },

  {
    image: Ashita, 
    name: "Ashita Jain",
    achievements: [
      { tournament: "U-19 Girls SGFI National Chess Championship (Team Event)", place: "1st", year: 2023, category: "State" },
      { tournament: "U-19 Girls Gujarat State Chess Championship", place: "1st", year: 2023, category: "State" },
      { tournament: "U-17 Girls SGFI Gujarat State Chess Championship", place: "1st", year: 2023, category: "State" },
      { tournament: "U-15 Girls Gujarat State Chess Championship", place: "1st", year: 2023, category: "State" },
      { tournament: "U-17 Girls Gujarat State Chess Selection", place: "3rd", year: 2023, category: "State" },
      { tournament: "Senior Women Gujarat State Chess Championship", place: "3rd", year: 2023, category: "State" },
      { tournament: "U-19 Girls SGFI Gujarat State Chess Championship", place: "3rd", year: 2023, category: "State" },
      { tournament: "U-17 Girls SGFI National Chess Championship (Team Event)", place: "4th", year: 2023, category: "National" },
      { tournament: "U-15 Girls Inter School Gujarat State Chess Championship", place: "1st", year: 2024, category: "State" },
      { tournament: "Open Gujarat Women State Team Chess Championship", place: "1st", year: 2024, category: "State" },
      { tournament: "U-17 Girls Khelmahakumbh Gujarat State Chess Championship", place: "1st", year: 2024, category: "State" },
      { tournament: "U-17 Girls Gujarat State Chess Selection", place: "2nd", year: 2024, category: "State" },
      { tournament: "U-15 Girls Gujarat State Chess Championship", place: "4th", year: 2024, category: "State" },
      { tournament: "National Women Team Chess Championship (Team Event)", place: "7th", year: 2024, category: "National" },
      { tournament: "National Amateur (Below 2300) Chess Championship", place: "9th", year: 2024, category: "National" },
      { tournament: "U-15 Girls National Chess Championship", place: "2nd", year: 2024, category: "National" }
    ],
  },

  {
    image: Jihan,  // Replace JihanImage with the actual image import or path
    name: "Jihan Shah",
    achievements: [
      // International Achievements
      { tournament: "Commonwealth Youth U-16 Chess Championship", place: "4th", year: 2024, category: "International" },
  
      // National Achievements
      { tournament: "48th National Sub Junior (U-15) Chess Championship", place: "2nd", year: 2024, category: "National" },
      { tournament: "43rd National Team Chess Championship (Team Event)", place: "3rd", year: 2024, category: "National" },
      { tournament: "U-19 Boys SGFI National Chess Championship (Team Event)", place: "2nd", year: 2023, category: "National" },
      { tournament: "National Women Team Chess Championship (Team Event)", place: "7th", year: 2024, category: "National" },
      { tournament: "National Amateur (Below 2300) Chess Championship", place: "9th", year: 2024, category: "National" },
      { tournament: "U-15 Girls National Chess Championship", place: "2nd", year: 2024, category: "National" },
  
      // State Achievements
      { tournament: "U-17 Boys Gujarat State Chess Championship", place: "1st", year: 2024, category: "State" },
      { tournament: "U-15 Boys Gujarat State Chess Championship", place: "3rd", year: 2024, category: "State" },
      { tournament: "U-15 Boys Gujarat State Chess Championship", place: "2nd", year: 2023, category: "State" },
      { tournament: "Open Gujarat Senior Rapid State Chess Championship", place: "2nd", year: 2023, category: "State" },
      { tournament: "U-17 Boys SGFI Gujarat State Chess Championship", place: "1st", year: 2023, category: "State" },
      { tournament: "U-15 Boys Inter School Gujarat State Chess Championship", place: "1st", year: 2023, category: "State" },
      { tournament: "U-13 Boys Gujarat State Chess Championship", place: "1st", year: 2023, category: "State" },
      { tournament: "Gujarat State U-15 Boys Chess Tournament", place: "2nd", year: 2023, category: "State" },
      { tournament: "U-19 Boys SGFI National Chess Championship (Team Event)", place: "2nd", year: 2023, category: "State" },
      { tournament: "U-15 Boys Gujarat State Chess Championship", place: "3rd", year: 2023, category: "State" },
    ],
  },

  {
    image: Ruhani,  // Replace RuhaniImage with the actual image import or path
    name: "Ruhani Asudani",
    achievements: [
      // International Achievements
      { tournament: "Commonwealth Youth Rapid Chess Championship 2024 - Under 14 Girls (2010 & 2011)", place: "3rd", year: 2024, category: "International" },
      { tournament: "Asian Amateur U-1700 Girls Rapid at Al Ain, UAE.", place: "3rd", year: 2024, category: "International" },
      { tournament: "Asian Amateur U-1700 Girls at Al Ain, UAE.", place: "2nd", year: 2024, category: "International" },
      { tournament: "7th Western Asian Youth Chess Championship", place: "12th", year: 2024, category: "International" },
  
      // National Achievements
      { tournament: "11th National Amateur Women Chess Championship Below 1700", place: "1st", year: 2024, category: "National" },
  
      // State Achievements
      { tournament: "U-15 Girls Interschool Gujarat State Chess Championship", place: "4th", year: 2025, category: "State" },
      { tournament: "U-17 Girls Gujarat State Chess Selection", place: "4th", year: 2024, category: "State" },
      { tournament: "Open Gujarat Women State Team Chess Championship", place: "6th", year: 2024, category: "State" },
      { tournament: "SGFI City U-17 Girls Gujarat State Chess Championship", place: "1st", year: 2024, category: "State" },
      { tournament: "KMK - AMC U-14 Girls", place: "1st", year: 2024, category: "State" },
      { tournament: "Khel Mahakumbh State U-14 Girls", place: "2nd", year: 2024, category: "State" },
      { tournament: "Gujarat State Selection for National Women Chess Championship", place: "3rd", year: 2024, category: "State" },
    ],
  },
  
  {
    image: Maharth,  // Replace MaharthImage with the actual image import or path
    name: "Maharth Godhani",
    achievements: [
      // International Achievements
      { tournament: "Asian Amateur U-1700 Blitz at Al Ain, UAE.", place: "2nd", year: 2024, category: "International" },
  
      // National Achievements
      { tournament: "U-15 Boys Interschol National Chess Championship", place: "5th", year: 2025, category: "National" },
  
      // State Achievements
      { tournament: "U-15 Boys Interschol Gujarat State Chess Championship", place: "1st", year: 2025, category: "State" },
      { tournament: "U-17 Boys Inter School Gujarat State Chess Championship", place: "2nd", year: 2024, category: "State" },
      { tournament: "U-19 Boys Gujarat State Chess Championship", place: "7th", year: 2024, category: "State" },
    ],
  },

  {
    image: Nitya,  // Replace Nitya with the actual image import or path
    name: "Nitya Laheri",
    achievements: [
      // International Achievements
      { tournament: "Commonwealth U-16 Girls Chess Championship", place: "8th", year: 2017, category: "International" },
      { tournament: "Commonwealth U-17 Girls Chess Championship", place: "6th", year: 2018, category: "International" },
  
      // State Achievements
      { tournament: "Khel Maha Kumbh U-17 Girls Chess Championship", place: "1st", year: 2018, category: "State" },
      { tournament: "Gujarat State Selection U-17 Girls Chess Championship", place: "2nd", year: 2018, category: "State" },
      { tournament: "Gujarat State Selection U-17 Girls Chess Championship", place: "1st", year: 2019, category: "State" },
      { tournament: "Open Age Women Khelmahakumbh Gujarat State Chess Championship", place: "4th", year: 2019, category: "State" },
      { tournament: "Interschool U-17 Girls Gujarat Chess Championship", place: "2nd", year: 2017, category: "State" },
      { tournament: "State Women Team Selection", place: "3rd", year: 2017, category: "State" },
      { tournament: "State Women Challenger's Selection", place: "3rd", year: 2017, category: "State" },
    ],
  },

  {
    image: Kian,  // Replace Kian with the actual image import or path
    name: "Kian Shah",
    achievements: [
      // International Achievements
      { tournament: "U-10 Open Western Asia Youth Online Chess Championship", place: "10th", year: 2021, category: "International" },
  
      // National Achievements
      { tournament: "8th National School U-7 Chess Championship", place: "4th", year: 2019, category: "National" },
  
      // State Achievements
      { tournament: "U-11 Boys Interschool Gujarat State Chess Selection", place: "1st", year: 2022, category: "State" },
      { tournament: "U-13 Boys Interschool Gujarat State Chess Selection", place: "5th", year: 2022, category: "State" },
      { tournament: "U-10 Boys Gujarat State Chess Selection", place: "2nd", year: 2021, category: "State" },
      { tournament: "U-8 Boys Inter School Gujarat State Selection", place: "2nd", year: 2019, category: "State" },
      { tournament: "Gujarat State Selection U-7 Open Chess Championship", place: "1st", year: 2019, category: "State" },
    ],
  },

  {
    image: Shreetu,  // Replace Shreetu with the actual image import or path
    name: "Shreetu Nandi",
    achievements: [
      // International Achievements
      { tournament: "Western Asian Youth and Junior Rapid Chess Championship 2019 (U-16G)", place: "5th", year: 2019, category: "International" },
  
      // State Achievements
      { tournament: "U-18 Girls Gujarat State Chess Championship", place: "1st", year: 2021, category: "State" },
      { tournament: "U-18 Girls Gujarat State Chess Championship", place: "3rd", year: 2022, category: "State" },
      { tournament: "U-19 Girls Gujarat State Chess Championship", place: "4th", year: 2022, category: "State" },
      { tournament: "U-17 Girls Khel Mahakumbh Gujarat State Chess Championship", place: "2nd", year: 2019, category: "State" },
    ],
  },

  {
    image: AbhayBandewar,  // Replace AbhayBandewar with the actual image import or path
    name: "Abhay Bandewar",
    achievements: [
      { tournament: "CJKS Premier Division Chess League - Chittagong - Team", place: "1st", year: 2024, category: "Rating" },
      { tournament: "1st Soa International Grandmaster Chess Tournament - Bhubneshwar", place: "20th", year: 2024, category: "Rating" },
      { tournament: "1st Seoni Open Rapid Chess Tournament - Seoni", place: "5th", year: 2024, category: "Rating" },
      { tournament: "CJKS Premier Division Chess League - Chittagong - Individual", place: "Board prize 1st", year: 2024, category: "Rating" },
      { tournament: "All India Open Rapid Rating Chess Tournament - Seoni", place: "3rd", year: 2023, category: "Rating" },
      { tournament: "Rangpur Below-2000 Chess Tournament - Rangpur", place: "1st", year: 2022, category: "Rating" },
      { tournament: "Sheikh Kamal 1st Division Chess League - Individual", place: "1st", year: 2022, category: "Rating" },
      { tournament: "SAIF Powertec 2nd Division Chess League at Dhaka - Individual", place: "1st", year: 2022, category: "Rating" },
      { tournament: "SAIF POWERTEC 2nd Division Chess League at Bangladesh - Individual", place: "1st", year: 2022, category: "Rating" },
      { tournament: "Sheikh Kamal 1st Division Chess League - Team", place: "3rd", year: 2022, category: "Rating" },
      { tournament: "SAIF Powertec 2nd Division Chess League at Dhaka - Team", place: "3rd", year: 2022, category: "Rating" },
      { tournament: "SAIF POWERTEC 2nd Division Chess League at Bangladesh - Team", place: "3rd", year: 2022, category: "Rating" }
    ],
  },
  

  {
    image: Veer,  // Replace Veer with the actual image import or path
    name: "Veer Patel",
    achievements: [
      // National Achievements
      { tournament: "U-11 Boys Interschol National Chess Championship", place: "8th", year: 2025, category: "National" },
  
      // State Achievements
      { tournament: "U-11 Boys Interschol Gujarat State Chess Championship", place: "5th", year: 2025, category: "State" },
      { tournament: "U-13 Boys Gujarat State Chess Championship", place: "6th", year: 2024, category: "State" },
      { tournament: "U-17 Boys Gujarat State Chess Selection", place: "9th", year: 2024, category: "State" },
      { tournament: "U-11 Boys Gujarat State Chess Championship", place: "5th", year: 2024, category: "State" },
      { tournament: "U-11 Boys Gujarat State Chess Championship", place: "6th", year: 2023, category: "State" },
    ],
  },

  {
    image: Dhyaan,  // Replace Dhyaan with the actual image import or path
    name: "Dhyaan Patel",
    achievements: [
      // National Achievements
      { tournament: "12th National School U-9 Open Chess Championship", place: "9th", year: 2024, category: "National" },
  
      // State Achievements
      { tournament: "U-11 Boys Interschol Gujarat State Chess Championship", place: "1st", year: 2025, category: "State" },
      { tournament: "U-9 Boys Gujarat State Chess Championship", place: "1st", year: 2024, category: "State" },
      { tournament: "U-11 Boys Gujarat State Chess Championship", place: "1st", year: 2024, category: "State" },
      { tournament: "U-9 Boys Inter School Gujarat State Chess Championship", place: "1st", year: 2023, category: "State" },
      { tournament: "U-13 Boys Interschol Gujarat State Chess Championship", place: "5th", year: 2025, category: "State" },
    ],
  },

  {
    image: Viaan,  // Replace Viaan with the actual image import or path
    name: "Viaan Makhani",
    achievements: [
      // National Achievements
      { tournament: "U-11 Boys National Chess Championship", place: "8th", year: 2024, category: "National" },
  
      // State Achievements
      { tournament: "U-13 Boys Gujarat State Chess Championship", place: "3rd", year: 2024, category: "State" },
      { tournament: "U-13 Boys Interschol Gujarat State Chess Championship", place: "8th", year: 2025, category: "State" },
      { tournament: "U-11 Boys Interschol Gujarat State Chess Championship", place: "6th", year: 2025, category: "State" },
      { tournament: "U-11 Boys Gujarat State Chess Championship", place: "8th", year: 2024, category: "State" },
      { tournament: "U-11 Boys Gujarat State Chess Championship", place: "7th", year: 2023, category: "State" },
      { tournament: "U-9 Boys Gujarat State Chess Championship", place: "5th", year: 2023, category: "State" },
    ],
  },

  {
    image: Shikha,  // Replace Shikha with the actual image import or path
    name: "Shikha Shah",
    achievements: [
      // National Achievements (Latest First)
      { tournament: "Gujarat State Selection for women National B Chess Championship", place: "1st", year: 2012, category: "State" },
      { tournament: "National U-17 Girls Tournament", place: "6th", year: 2008, category: "National" },
      { tournament: "National U-15 Sub-Junior Girls Tournament", place: "9th", year: 2008, category: "National" },
  
      // State Achievements (Latest First)
      { tournament: "Gujarat State Selection for women National B Chess Championship", place: "2nd", year: 2008, category: "State" },
      { tournament: "Gujarat State Selection U-19 Girls Chess Championship", place: "1st", year: 2009, category: "State" },
      { tournament: "Gujarat State Selection U-15 Girls Chess Championship", place: "1st", year: 2009, category: "State" },
      { tournament: "Gujarat State Selection U-19 Girls Chess Championship", place: "1st", year: 2008, category: "State" },
      { tournament: "Gujarat State Selection U-17 Girls Chess Championship", place: "1st", year: 2008, category: "State" },
      { tournament: "Gujarat State Selection U-15 Girls Chess Championship", place: "1st", year: 2008, category: "State" },
      { tournament: "Gujarat State Selection U-13 Girls Chess Championship", place: "1st", year: 2007, category: "State" },
      { tournament: "Gujarat State Selection U-15 Boys Chess Championship", place: "1st", year: 2007, category: "State" },
      { tournament: "Gujarat State Selection U-13 Boys Chess Championship", place: "1st", year: 2007, category: "State" },
      { tournament: "Gujarat State Selection U-15 Girls Chess Championship", place: "1st", year: 2007, category: "State" },
      { tournament: "Gujarat State Selection U-19 Girls Chess Championship", place: "1st", year: 2007, category: "State" },
      { tournament: "Gujarat State Selection U-25 Open Chess Championship", place: "2nd", year: 2007, category: "State" },
      { tournament: "Gujarat State Selection U-17 Girls Chess Championship", place: "2nd", year: 2007, category: "State" },
      { tournament: "Gujarat State Selection U-13 Girls Chess Championship", place: "1st", year: 2006, category: "State" },
      { tournament: "Gujarat State Selection U-15 Girls Chess Championship", place: "2nd", year: 2006, category: "State" },
      { tournament: "Gujarat State Selection U-19 Girls Chess Championship", place: "2nd", year: 2006, category: "State" },
      { tournament: "Gujarat State Selection U-17 Boys Chess Championship", place: "2nd", year: 2006, category: "State" },
      { tournament: "Gujarat State Selection U-25 Open Chess Championship", place: "6th", year: 2006, category: "State" },
      { tournament: "Gujarat State Selection U-13 Boys Chess Championship", place: "6th", year: 2006, category: "State" },
      { tournament: "Gujarat State Selection U-15 Boys Chess Championship", place: "9th", year: 2006, category: "State" },
    ],
  },
  
  {
    image: Adi,  // Replace Adi with the actual image import or path
    name: "Adi Jain",
    achievements: [
      // International Achievements (Latest First)
      { tournament: "Commonwealth U-10 Open Chess Championship", place: "8th", year: 2017, category: "International" },
  
      // National Achievements (Latest First)
      { tournament: "8th National school U-13 chess championship", place: "8th", year: 2019, category: "National" },
      { tournament: "30th National U-9 Open Chess Championship", place: "6th", year: 2016, category: "National" },
  
      // State Achievements (Latest First)
      { tournament: "U-14 Boys Gujarat State chess championship", place: "5th", year: 2021, category: "State" },
      { tournament: "Gujarat State Selection U-17 Open Chess Championship", place: "5th", year: 2019, category: "State" },
      { tournament: "U-16 Boys Inter school Gujarat State Selection", place: "2nd", year: 2019, category: "State" },
      { tournament: "U-12 Boys Inter school Gujarat State Selection", place: "2nd", year: 2019, category: "State" },
      { tournament: "Gujarat State Selection U-11 Open Chess Championship", place: "2nd", year: 2018, category: "State" },
      { tournament: "Gujarat State Selection U-13 Open Chess Championship", place: "1st", year: 2018, category: "State" },
      { tournament: "Gujarat State Selection U-11 Open Chess Championship", place: "3rd", year: 2017, category: "State" },
      { tournament: "Khel Maha Kumbh U-11 Open Chess Championship", place: "2nd", year: 2017, category: "State" },
      { tournament: "Khel Maha Kumbh U-9 Open Chess Championship", place: "2nd", year: 2016, category: "State" },
    ],
  },

  {
    image: Aditya,  // Replace Aditya with the actual image import or path
    name: "Aditya Melani",
    achievements: [
      // National Achievements (Latest First)
      { tournament: "28th National youth U-25 Open chess championship", place: "5th", year: 2019, category: "National" },
      { tournament: "8th National school U-17 chess championship", place: "2nd", year: 2019, category: "National" },
  
      // State Achievements (Latest First)
      { tournament: "U-19 boys Gujarat State selection", place: "3rd", year: 2019, category: "State" },
      { tournament: "Open age Gujarat state Khelmahakumbh", place: "2nd", year: 2019, category: "State" },
    ],
  },
  
  {
    image: Aadhya,  // Replace Aadhya with the actual image import or path
    name: "Aadhya Bhavsar",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-17 Girls Gujarat State Chess Selection", place: "8th", year: 2024, category: "State" },
      { tournament: "U-14 Girls SGFI State Chess championship", place: "2nd", year: 2023, category: "State" },
    ],
  },

  {
    image: Aadish,  // Replace Aadish with the actual image import or path
    name: "Aadish Shah",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "Open Gujarat State Team chess championship", place: "8th", year: 2022, category: "State" },
      { tournament: "U-12 Boys Gujarat State chess championship", place: "6th", year: 2022, category: "State" },
      { tournament: "U-14 Boys Gujarat State chess championship", place: "4th", year: 2021, category: "State" },
    ],
  },

  {
    image: Aaravarpit,  // Replace Aarav with the actual image import or path
    name: "Aarav Arpit Shah",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-15 Boys Interschol Gujarat State Chess championship", place: "2nd", year: 2025, category: "State" },
      { tournament: "U-13 Boys Interschol Gujarat State Chess championship", place: "4th", year: 2025, category: "State" },
      { tournament: "U-13 Boys Gujarat State Chess championship", place: "4th", year: 2024, category: "State" },
      { tournament: "U-17 Boys Gujarat State Chess championship", place: "4th", year: 2024, category: "State" },
      { tournament: "U-11 Boys Gujarat State chess championship", place: "2nd", year: 2023, category: "State" },
    ],
  },

  {
    image: AaravKumar,  // Replace AaravKumar with the actual image import or path
    name: "Aarav Kumar",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-13 Boys Gujarat State Chess championship", place: "2nd", year: 2024, category: "State" },
      { tournament: "U-19 Boys Gujarat State chess championship", place: "5th", year: 2024, category: "State" },
    ],
  },

  // {
  //   image: AaravTShah,  // Replace AaravTShah with the actual image import or path
  //   name: "Aarav T Shah",
  //   achievements: [
  //     // State Achievements (Latest First)
  //     { tournament: "Illinois State Tournament", place: "4th", year: 2022, category: "State" },
  //   ],
  // },

  {
    image: Aashvi,  // Replace AashviSingh with the actual image import or path
    name: "Aashvi Singh",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-11 Girls Inter School Gujarat State Chess Championship", place: "2nd", year: 2025, category: "State" },
      { tournament: "U-9 Girls Gujarat State chess championship", place: "1st", year: 2024, category: "State" },
      { tournament: "U-17 Girls Gujarat State chess championship", place: "6th", year: 2024, category: "State" },
      { tournament: "U-19 Girls Gujarat State chess championship", place: "8th", year: 2024, category: "State" },
      { tournament: "U-11 Girls Gujarat State chess championship", place: "8th", year: 2024, category: "State" },
      { tournament: "U-11 Girls Khel Mahakumbh state", place: "4th", year: 2024, category: "State" },
    ],
  },

  {
    image: Aatman,  // Replace AatmanRawal with the actual image import or path
    name: "Aatman Rawal",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-17 Boys Gujarat State Chess Selection", place: "10th", year: 2024, category: "State" },
      { tournament: "U-14 Boys SGFI State Chess Championship", place: "5th", year: 2023, category: "State" },
    ],
  },

  {
    image: Anushi,  // Replace AnushiPandit with the actual image import or path
    name: "Anushi Pandit",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-11 Girls Inter School Gujarat State Chess Championship", place: "10th", year: 2025, category: "State" },
      { tournament: "U-11 Girls Gujarat State Chess Championship", place: "7th", year: 2024, category: "State" },
      { tournament: "U-9 Girls Gujarat State Chess Championship", place: "9th", year: 2024, category: "State" },
      { tournament: "U-9 Girls Gujarat State Chess Championship", place: "5th", year: 2024, category: "State" },
    ],
  },

  {
    image: Arham,  // Replace ArhamTurakhia with the actual image import or path
    name: "Arham Turakhia",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-9 Boys Gujarat State Chess Championship", place: "2nd", year: 2024, category: "State" },
    ],
  },
  
  // {
  //   image: Daivya,  // Replace DaivyaNagda with the actual image import or path
  //   name: "Daivya Nagda",
  //   achievements: [
  //     // State Achievements (Latest First)
  //     { tournament: "U-11 Boys Interschol Gujarat State Chess Championship", place: "9th", year: 2025, category: "State" },
  //     { tournament: "U-11 Boys Gujarat State Chess Championship", place: "3rd", year: 2024, category: "State" },
  //     { tournament: "U-11 Boys Gujarat State Chess Championship", place: "7th", year: 2023, category: "State" },
  //     { tournament: "U-9 Boys Gujarat State Chess Championship", place: "6th", year: 2023, category: "State" },
  //   ],
  // },

  {
    image: Devansh,  // Replace DevanshPatel with the actual image import or path
    name: "Devansh Patel",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-9 Boys Inter School Gujarat State Chess Championship", place: "3rd", year: 2024, category: "State" },
      { tournament: "U-9 Boys Gujarat State Chess Championship", place: "7th", year: 2024, category: "State" },
    ],
  },
  
  {
    image: Dhanasvi,  // Replace DhanasviThanki with the actual image import or path
    name: "Dhanasvi Thanki",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-9 Girls Interschool Gujarat State Chess Championship", place: "9th", year: 2025, category: "State" },
      { tournament: "U-7 Girls Gujarat State Chess Championship", place: "7th", year: 2024, category: "State" },
    ],
  },
  
  {
    image: Dhvit,  // Replace DhvitMehta with the actual image import or path
    name: "Dhvit Mehta",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-9 Boys Inter School Gujarat State Chess Championship", place: "7th", year: 2024, category: "State" },
      { tournament: "U-9 Boys Gujarat State Chess Championship", place: "9th", year: 2024, category: "State" },
    ],
  },
  
  {
    image: Dirgh,  // Replace DirghKathiwala with the actual image import or path
    name: "Dirgh Kathiwala",
    achievements: [
      // State Achievements (Latest First)
      { tournament: "U-13 Boys Interschol Gujarat State Chess Championship", place: "10th", year: 2025, category: "State" },
  
      // District Achievements
      { tournament: "U-11 Khelmahakumbh Surat District", place: "1st", year: 2024, category: "District" },
      { tournament: "U-14 SGFI Surat District Chess Championship", place: "3rd", year: 2024, category: "District" },
  
      // West Zone Achievements
      { tournament: "U-11 CBSE Cluster Chess Tournament West Zone", place: "1st", year: 2023, category: "West Zone" },
    ],
  },

  {
    image: Harikrishna,  // Replace HarikrashnaParikh with the actual image import or path
    name: "Harikrishna Parikh",
    achievements: [
      // State Achievements
      { tournament: "Inter Zonal Gujarat Technological University", place: "1st", year: 2019, category: "State" },
    ],
  },

  {
    image: KrishTanna,  // Replace KrishTanna with the actual image import or path
    name: "Krish Tanna",
    achievements: [
      // National Achievements
      { tournament: "U-19 Boys SGFI National chess championship (Team Event)", place: "2nd", year: 2023, category: "National" },
  
      // State Achievements
      { tournament: "U-17 Boys Gujarat State chess championship", place: "5th", year: 2023, category: "State" },
      { tournament: "U-17 Boys Gujarat State Chess championship", place: "4th", year: 2024, category: "State" },
      { tournament: "U-17 Boys Inter School Gujarat State Chess championship", place: "4th", year: 2024, category: "State" },
      { tournament: "U-15 Boys Gujarat State Chess championship", place: "5th", year: 2024, category: "State" }
    ],
  },
  
  {
    image: KrishavShah,  // Replace KrishavShah with the actual image import or path
    name: "Krishav Shah",
    achievements: [
      // State Achievements
      { tournament: "U-15 Boys Interschol Gujarat State Chess championship", place: "3rd", year: 2025, category: "State" }
    ],
  },

  {
    image: MarmikShah,  // Replace MarmikShah with the actual image import or path
    name: "Marmik Shah",
    achievements: [
      // State Achievements
      { tournament: "Provonce state , France", place: "1st", year: 2015, category: "State" },
      { tournament: "Khel Maha Kumbh State U-9 Open Chess Championship", place: "4th", year: 2016, category: "State" },
      { tournament: "U-12 Boys Inter school Gujarat State Selection", place: "3rd", year: 2019, category: "State" },
  
      // District Achievements
      { tournament: "Bouche du rhone ...district, France", place: "1st", year: 2015, category: "District" },
      { tournament: "Khel Maha Kumbh District U-11 Open Chess Championship", place: "2nd", year: 2017, category: "District" }
    ],
  },

  {
    image: Risha,  // Replace RishaPatel with the actual image import or path
    name: "Risha Patel",
    achievements: [
      // State Level Achievements
      { tournament: "Under 7, Gujarat State", place: "2nd", year: 2023, category: "State" },
      { tournament: "Under 7, Gujarat State Inter School", place: "4th", year: 2023, category: "State" },
      { tournament: "Under-9, Gujarat State Inter School for Nationals", place: "3rd", year: 2024, category: "State" },
      { tournament: "U-9 Girls Interschool Gujarat State Chess Championship", place: "3rd", year: 2025, category: "State" },
  
      // District Level Achievements
      { tournament: "Under 7, Ahmedabad District", place: "4th", year: 2023, category: "District" },
      { tournament: "Under 9, Ahmedabad District", place: "6th", year: 2024, category: "District" },
    ],
  },
  

  {
    image: Rishit,  // Replace RishitSharma with the actual image import or path
    name: "Rishit Sharma",
    achievements: [
      { tournament: "U-14 Boys SGFI State Chess Championship", place: "1st", year: 2023, category: "State" },
      { tournament: "SGFI U-14 Boys National Chess Championship 2024-25", place: "3rd Team", year: 2025, category: "National" }
    ],
  },

  {
    image: Vishwanath,  // Replace Vishwanath with the actual image import or path
    name: "Vishwanath",
    achievements: [
      { tournament: "U-11 Boys Gujarat State Chess Championship", place: "2nd", year: 2024, category: "State" }
    ],
  },

  {
    image: ViniGandhi,  // Replace ViniGandhi with the actual image import or path
    name: "Vini Gandhi",
    achievements: [
      { tournament: "U-15 Girls Interschool Gujarat State Chess Championship", place: "5th", year: 2025, category: "State" },
      { tournament: "U-13 Girls Interschool Gujarat State Chess Championship", place: "9th", year: 2025, category: "State" }
    ],
  },
  
  {
    image: Vihana,  // Replace VihanaThanki with the actual image import or path
    name: "Vihana Thanki",
    achievements: [
      { tournament: "U-9 Girls School State Chess Selection", place: "5th", year: 2022, category: "State" }
    ],
  },

  {
    image: VihanParikh,  // Replace VihanParikh with the actual image import or path
    name: "Vihan Parikh",
    achievements: [
      { tournament: "U-14 Boys Khel Mahakumbh Gujarat State Chess Championship", place: "7th", year: 2024, category: "State" },
      { tournament: "U-17 Boys Gujarat State Chess Selection", place: "8th", year: 2024, category: "State" },
      { tournament: "U-11 Boys Gujarat State Chess Championship", place: "4th", year: 2023, category: "State" },
      { tournament: "U-11 Boys Ahmedabad District Chess Championship", place: "3rd", year: 2023, category: "District" }
    ],
  },

  {
    image: SohamN,  // Replace SohamNagrecha with the actual image import or path
    name: "Soham Nagrecha",
    achievements: [
      { tournament: "U-14 Boys Khel Mahakumbh Gujarat State Chess Championship", place: "4th", year: 2024, category: "State" }
    ],
  },

  {
    image: Saumil,  // Replace SaumilNair with the actual image import or path
    name: "Saumil Nair",
    achievements: [
      { tournament: "Inter University West Zone - Gujarat University (Team Event)", place: "2nd", year: 2019, category: "State" }
    ],
  },
  
  
  
  
  
  
  
  
  
  
  
  
];

const Stories = () => {
  return (
    <AwesomeSlider animation="cubeAnimation" fillParent={false}>
      {stories.map((student, index) => (
        <div key={index} className="story-slide">
          <div className="student-info">
            <img src={student.image} alt={student.name} className="student-image" />
            <div className="student-details">
              <h2>{student.name}</h2>
              <table className="achievements-table">
                <thead>
                  <tr>
                    <th>Tournament Name</th>
                    <th>Place</th>
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                  {student.achievements.map((achievement, i) => (
                    <tr key={i}>
                      <td>{achievement.tournament}</td>
                      <td>{achievement.place}</td>
                      <td>{achievement.year}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
    </AwesomeSlider>
  );
};

export default Stories;
