import React from "react";
import "./Footer.css";
import { FaFacebook, FaInstagram, FaYoutube, FaWhatsapp } from "react-icons/fa"; // Updated to WhatsApp
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { BiMap } from "react-icons/bi";

export default function Footer() {
    return (
        <footer className="footer-container">
            {/* Quick Links */}
            <div className="footer-column">
                <h3>Quick Links</h3>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/about">Pricing</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
                <div className="follow-us">
                    <h3>Follow Us</h3>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/p/Apexchessacademy-100063824540869/" target="_blank" rel="noreferrer"><FaFacebook /></a>
                        <a href="https://www.instagram.com/apexchessacademy/" target="_blank" rel="noreferrer"><FaInstagram /></a>
                        <a href="https://www.youtube.com/@apexchessacademy2525" target="_blank" rel="noreferrer"><FaYoutube /></a>
                        <a href="https://wa.me/918866888640" target="_blank" rel="noreferrer"><FaWhatsapp /></a> {/* Updated to WhatsApp */}
                    </div>
                </div>
            </div>

            {/* Branch 1 */}
            <div className="footer-column">
                <h3>Apex Academy, Vejalpur Branch</h3>
                <p><BiMap /> 16/B, Jaltarang Society, Near Police Chowky, Jivraj Park, Vejalpur</p>
                <p><AiOutlinePhone /> +91 8866888640</p>
                <p><AiOutlineMail /> apexchessacademy@gmail.com</p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.5331212798324!2d72.51797457962952!3d23.0041897255209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e857fb85e6ded%3A0xf3d28da1cd9e83fc!2sApex%20Chess%20Academy!5e0!3m2!1sen!2sus!4v1737896062840!5m2!1sen!2sus"
                    width="300"
                    height="200"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Vejalpur Branch"
                ></iframe>
                <a href="https://maps.app.goo.gl/XjJg6QHn5GiZUYSUA" target="_blank" rel="noreferrer">View on Google Maps</a>
            </div>

            {/* Branch 2 */}
            <div className="footer-column">
                <h3>Apex Academy, Bopal Branch</h3>
                <p><BiMap /> SF-224, Gala Hub, Beside Honest Garden Restaurant, South Bopal, Ahmedabad</p>
                <p><AiOutlinePhone /> +91 8866888640</p>
                <p><AiOutlineMail /> apexchessacademy@gmail.com</p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16654.163102326875!2d72.46683359570244!3d23.02187411667123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b541984b90d%3A0xa9603a219c7c3465!2sApex%20Chess%20Academy_Bopal!5e0!3m2!1sen!2sus!4v1737896003258!5m2!1sen!2sus"
                    width="300"
                    height="200"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Branch 2 Location"
                ></iframe>
                <a href="https://maps.app.goo.gl/t3Bkr38PdGunh9Rm7" target="_blank" rel="noreferrer">View on Google Maps</a>
            </div>
        </footer>
    );
}
