import Card from "./Card";
import { FaChessKing, FaChessBoard, FaBrain } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Header.css";
import apexImage from './image2.png'; 

export default function Header() {
    return (
        <div className="header">
            <div className="top">
                <div className="left">
                    <h1>Shaping Chess Minds<br /></h1>
                    <h4>At Apex Chess Academy, we don't just play chess — we craft champions. Believe in moves. Believe in your future.</h4>
                    
                    <div className="btn-container">
                        <a href="https://members.apexchess.academy" className="button-filled">Members Login</a>
                        <a href="https://members.apexchess.academy/register" className="button-outline">Members Sign Up</a>
                    </div>
                </div>
                <div className="right">
                    <div className="main-image-container">
                        <img src={apexImage} alt="intro placeholder" />
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="card-container">
                    <Card
                        icon={<FaChessKing />}
                        title={"Master the Game"}
                        description={
                            <>
                                Unleash your inner grandmaster with personalized coaching, deep strategies, and in-depth{' '}
                                <Link to="/game-analysis" style={{ color: 'green', fontWeight: 'bold', textDecoration: 'none' }}>
                                    game analysis
                                </Link>
                                .
                            </>
                        }
                    />
                    <Card
                        icon={<FaChessBoard />}
                        title={"Build Your Strategy"}
                        description={"Learn the art of planning and foresight, honing your ability to think multiple moves ahead and outplay any opponent."}
                    />
                    <Card
                        icon={<FaBrain />}
                        title={"Sharpen Your Mind"}
                        description={"Enhance critical thinking, problem-solving, and decision-making skills through engaging chess puzzles and analysis."}
                    />
                </div>
            </div>
        </div>
    );
}
