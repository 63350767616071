import React from 'react';
import { Link } from 'react-router-dom';
import './Courses.css';

const CourseFees = () => {
    const batches = [
        { id: 1, mode: 'Online', days: 'Monday', time: '4:00 - 6:00 PM', course: 'Middle Game & End Game' },
        { id: 2, mode: 'Online', days: 'Monday', time: '6:15 - 8:15 PM', course: 'e4-e5, d4-d5 and Nimzo - Black Side' },
        { id: 3, mode: 'Online', days: 'Tuesday & Thursday', time: '6:15 - 8:15 PM', course: 'Opening, Middle Game & End Game' },
        { id: 4, mode: 'Online', days: 'Tuesday & Friday', time: '4:00 - 6:00 PM', course: 'Opening, Middle Game & End Game' },
        { id: 5, mode: 'Online', days: 'Wednesday', time: '4:00 - 6:00 PM', course: '1.d4 Opening Repertoire - White Side' },
        { id: 6, mode: 'Online', days: 'Wednesday', time: '6:15 - 8:15 PM', course: '1.e4 Opening Repertoire - White Side' },
        { id: 7, mode: 'Online', days: 'Thursday', time: '4:00 - 6:00 PM', course: 'g6 setup based Repertoire - Black Side' },
        { id: 8, mode: 'Offline', days: 'Friday', time: '6:15 - 8:15 PM', course: 'Middle Game & End Game' },
        { id: 9, mode: 'Online', days: 'Monday to Friday', time: '3:00 - 4:00 PM', course: 'Opening, Middle Game & End Game' },
    ];

    return (
        <div className="course-fees-page">
            <h1 className="page-title">Group Batches (Vejalpur Branch)</h1>

            <div className="batch-table-container">
                <table className="batch-table">
                    <thead>
                        <tr>
                            <th>Batch</th>
                            <th>Mode</th>
                            <th>Days</th>
                            <th>Time</th>
                            <th>Course</th>
                        </tr>
                    </thead>
                    <tbody>
                        {batches.map((batch) => (
                            <tr key={batch.id}>
                                <td>{`Batch-${batch.id}`}</td>
                                <td>{batch.mode}</td>
                                <td>{batch.days}</td>
                                <td>{batch.time}</td>
                                <td>{batch.course}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Session Policy Notes */}
            <div className="notes-section">
                <h3>Important Notes</h3>
                <ul>
                    <li><strong>Opening Batches:</strong> Sessions will not be carried forward. PGN files will be provided.</li>
                    <li><strong>Middle Game & End Game Batches:</strong> Sessions can be carried forward for one month if paid quarterly or more.</li>
                </ul>
            </div>

            {/* Fee Structure */}
            <div className="fees-section">
                <h2>Fee Structure</h2>
                <p><strong>₹400 per hour</strong> for group batches.</p>
                <p>Example: A 2-hour session costs <strong>₹800</strong>.</p>
                <p>Fees are payable before the start of the course.</p>
                <p>For personal sessions, please <Link to="/contact" className="contact-link">contact us</Link>.</p>
            </div>

            {/* Contact Section */}
            <div className="contact-section">
                <h2>Contact Us</h2>
                <p>Email: <a href="mailto:apexchessacademy@gmail.com">apexchessacademy@gmail.com</a></p>
                <p>Phone: <a href="tel:+918866888640">+91 8866888640</a></p>
            </div>
        </div>
    );
};

export default CourseFees;
