import React from "react";
import { AiFillGithub, AiFillHeart } from "react-icons/ai";
import { CgFigma } from "react-icons/cg";
import "./Copyright.css";

export default function Copyright() {
    return (
        <footer className="copyright">
            <div className="innertext">
                © {new Date().getFullYear()} Apex Chess Academy. All rights reserved. 
            </div>
        </footer>
    );
}
